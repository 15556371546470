<template>
  <div class="detail">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="detailBox wrap">
      <div class="detailHd">
        <div class="detailTitle">{{infor.title}}</div>
        <div class="detailTitleB">
          <span>发布时间：{{ dayjs(infor.create_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>浏览：{{infor.view_number}}</span>
        </div>
        <div class="detailTitleLine"></div>
      </div>
      <div class="detailCont" v-html="infor.content">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'detail',
  data(){
    return {
      infor: {
        title: '',
        create_time: '',
        view_number: 0,
        content: ''
      },
      listId: ''
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.id){
      _this.listId = _this.$route.query.id
    }
    _this.getDetail(_this.listId)
  },
  methods: {
    // 查详情
    async getDetail(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.infor = data.data;
    },
  }
}
</script>